.App {
  text-align: center;
}

body {
  min-height: 100vh;
}

#intro-container {
  /* padding: 120px 0 100px 0; */
  background-position: 0px 80px;
  /* min-height: calc(100vh - 40px); */
  min-height: 100vh;
  background-image: url("./background_visible.png");
  /* background-position: center right; */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

footer {
  margin-top:calc(5% + 10px);
  padding-bottom: 20px
}